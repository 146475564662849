<template>
<v-sheet >
  <v-sheet   max-width="1240" width="100%" class="mx-auto d-flex pa-6">
    <v-sheet width="250" min-width="250" class="pt-16" >


      <category-chip text="Всі курси" />
      <category-chip text="Популярне" class="mt-2" />
      <category-chip text="Нові курси" class="mt-2" />
      <category-chip text="Безкоштовні" class="mt-2" />
      <category-chip text="Доступні курси" class="mt-2" />
      <category-chip text="Преміум курси" class="mt-2" />

      <h3 class="mt-10">{{ $t('Categories') }}</h3>

      <category-chip text="Програмування"  class="mt-2" />
      <category-chip text="Дизайн" class="mt-2" />
      <category-chip text="Фітнес та здоровʼя" class="mt-2" />
      <category-chip text="Мистецтво" class="mt-2" />
      <category-chip text="Фітодизайн" class="mt-2" />
      <category-chip text="Мови" class="mt-2" />


    </v-sheet>

    <div style="width: 100%">
      <h1>{{  titleText  }}  </h1>
      <v-divider :style="`border-color : ${wsBACKGROUND}`" class="mt-3" />

      <div v-for="(item,i) in items" :key="i">
        <ws-link :to="localeLink(`market/courses/${item.uuid}`)">
          <div class="d-flex flex-row py-7" style="width: 100%" >

            <v-sheet
                v-if="!item.expand"
                :style="`
              background : url('${item.img}');
              background-size: cover
              background-position : center
              `"
                style="border-radius : 8px"
                class="d-flex align-center justify-center mr-4"
                :color="wsBACKGROUND"
                width="286" height="176" min-width="286" >

              <img v-if="!item.img"
                   height="32" width="38" alt="WeStudy"
                   style="object-fit: cover;object-position: 0"
                   src="https://westudy.ua/library/img/westudy_logo.png" >

            </v-sheet>

            <div class="d-flex flex-column" style="width: 100%">

              <div class="d-flex justify-space-between">
                <div style="max-width: 430px">
                  <h4 style="font-size: 18px;">{{ item.name }}</h4>
                  <h5 class="wsACCENT font-weight-regular mt-2">
                    <span class="mr-2" v-if="item.has_certificates">Має сертифікати</span>
                    <span >{{ item.scheduled ? 'Живі зустрічі' : 'Все в записі' }}  </span>
                  </h5>
                </div>

                <h4 class="ml-5 text-no-wrap" v-if="item.is_payed">{{item.has_payment_plans ? item.minimum_price : item.price}} ГРН</h4>
              </div>


              <div class="flex-grow-1"></div>
              <div class="d-flex align-center">
                <v-sheet
                    :color="wsBACKGROUND"
                    class="d-flex align-center justify-center round mr-2"
                    width="40" min-width="40"
                    height="40" min-height="40"
                >
                  <v-icon :color="wsACCENT">mdi-school-outline</v-icon>
                </v-sheet>
                <h5 :class="!hover ? 'wsACCENT' : 'WHITE'" class="font-weight-regular">
                  {{ item.business }}
                </h5>
              </div>

            </div>


          </div>
        </ws-link>

        <v-divider :style="`border-color : ${wsBACKGROUND}`"  />
      </div>


    </div>



  </v-sheet>

</v-sheet>
</template>

<script>
import entitiesFunctions from "@/mixins/entitiesFunctions";
import categoryChip from "@/modules/market/components/Courses/UI/categoryChip";

export default {
  name: "MarketCourses",
  mixins : [entitiesFunctions],
  components : {
    categoryChip
  },
  data() {
    return {
      items : [],
      selectedCategory : null,
      selectedFilter : null,
    }
  },
  computed : {
    titleText() {
      if (this.search) {
        return `${this.$t('SearchResultsFor')} : "${this.search}"`
      }
      if (this.selectedCategory && !this.search) {
        return this.selectedCategory
      }
      return this.$t('SearchCourses')
    },
    search() {
      const query = this.$route.query;
      if (query.search) {
        return query.search
      }
      return null
    }
  },
  watch : {
    search() {
      this.initPage()
    }
  },
  methods : {
    async initPage() {
      const query = this.$route.query;
      let data = {}

      if (query.search) {
        data = {
          search: query.search
        }
      }

      // let result = await this.$store.dispatch("market/GET_COURSES" , data);
      let result = await this.$store.dispatch("market/GET_COURSES_NEW" , data);

      this.items = result
    }
  },
  async mounted() {
    this.initPage()
  }

}
</script>

<style scoped>

</style>